const DE = {
  ZOOM_IN: 'Ansicht vergrößern',
  ZOOM_OUT: 'Ansicht verkleinern',
  TOPIC_SHAPE: 'Themen Gestaltung',
  TOPIC_ADD: 'Thema hinzufügen',
  TOPIC_DELETE: 'Thema löschen',
  TOPIC_ICON: 'Symbol hinzufügen',
  TOPIC_LINK: 'Verbindung hinzufügen',
  TOPIC_RELATIONSHIP: 'Beziehung',
  TOPIC_COLOR: 'Themenfarbe',
  TOPIC_BORDER_COLOR: 'Thema Randfarbe',
  TOPIC_NOTE: 'Notiz hinzufügen',
  FONT_FAMILY: 'Schrifttyp',
  FONT_SIZE: 'Schriftgröße',
  FONT_BOLD: 'Fette Schrift',
  FONT_ITALIC: 'Kursive Schrift',
  UNDO: 'Rückgängig machen',
  REDO: 'Wiederholen',
  INSERT: 'Einfügen',
  SAVE: 'Sichern',
  NOTE: 'Notiz',
  ADD_TOPIC: 'Thema hinzufügen',
  LOADING: 'Laden ...',
  EXPORT: 'Exportieren',
  PRINT: 'Drucken',
  PUBLISH: 'Publizieren',
  COLLABORATE: 'Mitbenutzen',
  HISTORY: 'Historie',
  DISCARD_CHANGES: 'Änderungen verwerfen',
  FONT_COLOR: 'Textfarbe',
  SAVING: 'Sichern ...',
  SAVE_COMPLETE: 'Sichern abgeschlossen',
  ZOOM_IN_ERROR: 'Zoom zu hoch.',
  ZOOM_ERROR: 'Es kann nicht weiter vergrößert bzw. verkelinert werden.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED: 'Thema konnte nicht angelegt werden. Bitte wählen Sie nur ein Thema aus.',
  ONE_TOPIC_MUST_BE_SELECTED: 'Thema konnte nicht angelegt werden. Es muss ein Thema ausgewählt werden.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED_COLLAPSE: 'Kinderknoten können nicht eingefaltet werden. Es muss ein Thema ausgewäht werden.',
  SAVE_COULD_NOT_BE_COMPLETED: 'Sichern wurde nicht abgeschlossen. Versuchen Sie es später nocheinmal.',
  UNEXPECTED_ERROR_LOADING: 'E tut uns Leid, ein unerwarteter Fehler ist aufgetreten.\nVersuchen Sie, den Editor neu zu laden. Falls das Problem erneut auftritt, bitte kontaktieren Sie uns unter support@wisemapping.com.',
  MAIN_TOPIC: 'Hauptthema',
  SUB_TOPIC: 'Unterthema',
  ISOLATED_TOPIC: 'Isoliertes Thema',
  CENTRAL_TOPIC: 'Zentrales Thema',
  SHORTCUTS: 'Tastaturkürzel',
  ENTITIES_COULD_NOT_BE_DELETED: 'Konnte das Thema oder die Beziehung nicht löschen. Es muss mindest ein Eintrag ausgewählt sein.',
  AT_LEAST_ONE_TOPIC_MUST_BE_SELECTED: 'Es muss mindestens ein Thema ausgewählt sein.',
  CLIPBOARD_IS_EMPTY: 'Es gibt nichts zu kopieren. Die Zwischenablage ist leer.',
  CENTRAL_TOPIC_CAN_NOT_BE_DELETED: 'Das zentrale Thema kann nicht gelöscht werden.',
  RELATIONSHIP_COULD_NOT_BE_CREATED: 'Die Beziehung konnte nicht angelegt werden. Es muss erst ein Vater-Thema ausgewählt werden, um die Beziehung herzustellen.',
  SELECTION_COPIED_TO_CLIPBOARD: 'Themen in der Zwischenablage',
  WRITE_YOUR_TEXT_HERE: 'Schreiben Sie ihre Notiz hier ...',
  REMOVE: 'Entfernen',
  ACCEPT: 'Akzeptieren',
  CANCEL: 'Abbrechen',
  LINK: 'Verbindung',
  OPEN_LINK: 'Öffne URL',
  SESSION_EXPIRED: 'Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an.',
  URL_ERROR: 'URL nicht gültig',
  ACTION: 'Aktion',
  CREATE_SIBLING_TOPIC: 'Erzeuge ein Schwester Thema',
  CREATE_CHILD_TOPIC: 'Eryeuge ein Unterthema',
  DELETE_TOPIC: 'Lösche Thema',
  EDIT_TOPIC_TEXT: 'Editiere Thematext',
  JUST_START_TYPING: 'Einfach mit der Eingabe beginnen',
  CANCEL_TEXT_CHANGES: 'Textänderungen abbrechen',
  TOPIC_NAVIGATION: 'Themen Navigation',
  ARROW_KEYS: 'Pfeiltasten',
  SELECT_MULTIPLE_NODES: 'Wähle mehrfache Knoten aus',
  UNDO_EDITION: 'Änderungen rückgängig machen',
  REDO_EDITION: 'Änderung nochmal ausführen',
  SELECT_ALL_TOPIC: 'Wähle alle Themen aus',
  CHANGE_TEXT_BOLD: 'Ändere Text in fette Schrift',
  SAVE_CHANGES: 'Änderungen sichern',
  CHANGE_TEXT_ITALIC: 'Ändere Text in kursive Schrift',
  DESELECT_ALL_TOPIC: 'Deselektiere alle Themen',
  COLLAPSE_CHILDREN: 'Kindknoten zusammenklappen',
  KEYBOARD_SHORTCUTS_MSG: 'Tastenkürzel helfen Zeit zu sparen und erlauben die Arbeit nur mit der Tatstatur, s.d. Sie niemals die Hand von der Tastatur nehmen müßen, um die Maus zu bedienen.',
  COPY_AND_PASTE_TOPICS: 'Kopieren und Einsetzen von Themen',
  MULTIPLE_LINES: 'Füge mehrer Textzeilen hinzu',
  BACK_TO_MAP_LIST: 'Zurück zur Kartenliste',
  KEYBOARD_SHOTCUTS: 'Tastaturkürzel',

};

export default DE;
