import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() =>
    createStyles({
        paper: {
            maxWidth: '420px',
        },
    })
);
